<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >

    <!-- OPTIONS & MANAGEMENT WIDGETS -->
    <v-row v-if="userLevelAboveAdmin">

      <!-- SEARCH FORM -->
      <v-col cols="12" md="4">
        <v-card class="my-0">
          <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" class="pa-0">
                    <v-radio-group row v-model="form.search.searchBy">
                      <template v-slot:label>
                        <div>{{ $t('views.merchants.searchBy') }}<br></div>
                      </template>
                      <v-radio :label="$t('views.merchants.id')" value="merchant_id"></v-radio>
                      <v-radio :label="$t('views.merchants.name')" value="name"></v-radio>
                      <v-radio :label="$t('views.merchants.phone')" value="phone"></v-radio>
                      <v-radio :label="$t('views.merchants.email')" value="email"></v-radio>
                      <v-radio :label="$t('views.merchants.address')" value="address"></v-radio>
                      <!-- <v-radio :label="Organization" value="organization_name"></v-radio> -->
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" class="pa-0 d-flex justify-space-between">
                    <v-form @submit.native.prevent @submit="search">
                      <v-text-field dense outlined v-model="form.search.keyword"
                        placeholder="(keyword)" clearable>
                      </v-text-field>
                    </v-form>
                    <!-- <v-spacer></v-spacer>
                    <v-btn fab small color="warning" @click="search">
                      <v-icon>mdi-book-search</v-icon>
                    </v-btn> -->
                  </v-col>
                </v-row>
                
              </v-container>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Scope Selector (if it's the superadmin) -->
      <v-col cols="12" md="4">
        <ScopeSelector org-only
          @org-selected="onOrganizationSelected">
        </ScopeSelector>
      </v-col>

      <!-- BUTTONS FOR CREATION -->
      <v-col cols="12" md="4" class="d-flex justify-end">
        <div>
          <v-btn x-large block rounded @click="resetCreateModel();dialog.create.show = true;" color="primary">
            <v-icon left>mdi-plus-box-outline</v-icon>
            {{ $t('views.merchants.createButton') }}
          </v-btn>
          <br>
          <v-btn x-large block rounded @click="showBatchCreationDialog" color="secondary">
            <v-icon left>mdi-plus-box-multiple-outline</v-icon>
            {{ $t('views.merchants.batchCreateButton') }}
          </v-btn>
        </div>
      </v-col>

    </v-row>
      
    <!-- LIST OF MERCHANTS -->
    <v-row>
      <v-col cols="12">

        <v-card>
          <v-card-text>

            <SSRTable ref="mch-list" :data-url="loadUrl" :data-key="'merchants'" :schema="schema">
              <div slot="name" slot-scope="{item}">
                <span class="text-body-1">{{ item.name }}</span><br>
                <span style="color:#999999">{{ item.merchant_id }}</span>
              </div>

              <div slot="contact" slot-scope="{item}" class="py-1">
                <span class="text-body-1">{{ item.phone }}</span><br>
                <span>{{ item.email }}</span><br>
                <span>{{ item.address }}</span>
              </div>

              <div slot="invoice" slot-scope="{item}" class="py-1">
                <span v-if="item.invoice && item.invoice.type === 'b2b'">
                  {{ $t('views.merchants.invoice.B2B') }}
                </span>
                <span v-else-if="item.invoice && item.invoice.type === 'b2c'">
                  {{ $t('views.merchants.invoice.B2C') }}
                </span>
                <br/>
                <span>{{ item.invoice ? item.invoice.title : '' }}</span><br/>
                <span>{{ item.invoice ? item.invoice.taxId : '' }}</span><br/>
                <span>{{ item.invoice ? item.invoice.email : '' }}</span>
              </div>
              <div slot="beneficiary" slot-scope="{item}" class="py-1">
                <span>{{ item.beneficiary ? item.beneficiary.bankName  : '' }}</span><br/>
                <span>{{ item.beneficiary ? item.beneficiary.bankId : '' }}</span><br/>
                <span>{{ item.beneficiary ? item.beneficiary.accountName : '' }}</span><br/>
                <span>{{ item.beneficiary ? item.beneficiary.accountNo : '' }}</span>
              </div>
              
              <div slot="actions" slot-scope="{item}">
                <v-btn fab x-small elevation="0" color="warning" @click="showUpdateMerchantDialog(item.merchant_id)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn rounded depressed small color="indigo" class="ml-1"
                  @click="showDialogOfPaymentConfigs(item)">
                  <v-icon left>mdi-credit-card-outline</v-icon>
                  {{ $t('views.merchants.paymentServicesButton') }}
                </v-btn>
                <v-btn rounded depressed small color="teal darken-3" class="ml-1"
                  @click="showDialogOfCounterSettings(item)">
                  <v-icon left>mdi-cash-register</v-icon>
                  條碼管理
                </v-btn>
                <v-btn rounded depressed small color="blue" class="ml-1"
                  @click="showDialogOfMerchantSettings(item)">
                  <v-icon left>mdi-cog</v-icon>
                  {{ $t('views.merchants.merchantSettingsButton') }}
                </v-btn>
                <!-- <v-btn rounded depressed small color="light-green darken-3" class="ml-1"
                  @click="showDialogOfCommissionFee(item)">
                  <v-icon left>mdi-hand-coin</v-icon>
                  {{ $t('views.merchants.commissionFeeButton') }}
                </v-btn> -->
              </div>
            </SSRTable>

          </v-card-text>
        </v-card>

      </v-col>

    </v-row>

    <!-- Dialog - Setup Merchant's Payment Services -->
    <PaymentConfigDialog ref="dialog-payment-config" :show.sync="dialog.paymentConfigs.show"
      :merchant="dialog.paymentConfigs.merchant"
      @close="dialog.paymentConfigs.show = false">
    </PaymentConfigDialog>

    <!-- Dialog - Counter Settings -->
    <v-dialog v-model="dialog.counters.show">
      <v-card>
        <v-card-title>
          櫃檯與支付條碼管理｜{{ dialog.counters.merchant['name'] }}
        </v-card-title>
        <v-card-text>
          <CounterSettingsView
            :merchant-id="dialog.counters.merchant['merchant_id']"
            :merchant-name="dialog.counters.merchant['name']">
          </CounterSettingsView>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog - More Merchant Settings -->
    <v-dialog v-model="dialog.settings.show">
      <v-card>
        <MerchantSettingsView
          :merchant-id="dialog.settings.merchant['merchant_id']"
          :merchant-name="dialog.settings.merchant['name']"
          :merchant="dialog.settings.merchant">
        </MerchantSettingsView>
      </v-card>
    </v-dialog>
    

    <!-- Dialog - Batch creation -->
    <UploadPreviewDialog title="Merchants Batch Creation" :show.sync="dialog.batchCreate.show"
      :sample-url="batchCreateSampleUrl"
      success-message="Successfully submit batch."
      fail-message="Failed to submit batch."
      @submit="batchCreateMerchant">
    </UploadPreviewDialog>

    <!-- Dialog - Create a new merchant -->
    <v-dialog v-model="dialog.create.show" max-width="50%">
      <v-card>
        <v-card-title>
          <strong>{{ $t('views.merchants.dialog.titleCreate') }}</strong>
        </v-card-title>

        <v-card-text>
          <v-form ref="mch-create-form" :disabled="dialog.create.isLoading">

            <v-container>
              <v-row>

                <v-col cols="12" class="mb-4">
                  <ScopeSelector dense org-only v-if="user.level === 'superadmin'"
                    @org-selected="onCreateFormOrgSelected">
                  </ScopeSelector>
                </v-col>
                <!-- <v-col cols="12" md="6"></v-col> -->

                <v-col cols="12" md="6">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.merchantId')" prepend-icon="mdi-pound"
                    v-model="dialog.create.model.merchantId"
                    :error-messages="validation.firstError('dialog.create.model.merchantId')">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.merchantName')" prepend-icon="mdi-store"
                    v-model="dialog.create.model.name"
                    :error-messages="validation.firstError('dialog.create.model.name')">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.customerMerchantId')"  prepend-icon="mdi-pound"
                    v-model="dialog.create.model.customerMerchantId"
                    :error-messages="validation.firstError('dialog.create.model.customerMerchantId')">
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.email')" prepend-icon="mdi-email"
                    v-model="dialog.create.model.email"
                    :error-messages="validation.firstError('dialog.create.model.email')">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.phone')" prepend-icon="mdi-phone"
                    v-model="dialog.create.model.phone"
                    :error-messages="validation.firstError('dialog.create.model.phone')">
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="8">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.address')" prepend-icon="mdi-map-marker"
                    v-model="dialog.create.model.address"
                    :error-messages="validation.firstError('dialog.create.model.address')">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-checkbox :label="$t('views.merchants.dialog.defaultPsSet')"
                    v-model="dialog.create.model.applyDefaultPaymentConfigs">
                  </v-checkbox>
                </v-col>

              </v-row>
              <br/>
              <!-- SECTION - 綁定發票資訊 -->
              <span class="text-h3">發票資訊</span>
              <v-row>
                <v-col cols="12">
                  <v-radio-group row v-model="dialog.create.model.invoice.type">
                    <template v-slot:label>
                      <div>{{ $t('views.merchants.invoice.type') }}<br></div>
                    </template>
                    <v-radio :label="$t('views.merchants.invoice.NONE')" value="n/a"></v-radio>
                    <v-radio :label="$t('views.merchants.invoice.B2C')" value="b2c"></v-radio>
                    <v-radio :label="$t('views.merchants.invoice.B2B')" value="b2b"></v-radio>                    
                  </v-radio-group>
                </v-col>
                <template v-if="dialog.create.model.invoice.type==='b2b'" >
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense :label="$t('views.merchants.invoice.title')" prepend-icon="mdi-store"
                      v-model="dialog.create.model.invoice.title"
                      :error-messages="validation.firstError('dialog.create.model.invoice.title')">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense :label="$t('views.merchants.invoice.taxId')" prepend-icon="mdi-pound"
                      v-model="dialog.create.model.invoice.taxId"
                      :error-messages="validation.firstError('dialog.create.model.invoice.taxId')">
                    </v-text-field>
                  </v-col>
                </template>
                <template v-if="dialog.create.model.invoice.type!=='n/a'">
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense :label="$t('views.merchants.dialog.email')" prepend-icon="mdi-email"
                        v-model="dialog.create.model.invoice.email"
                        :error-messages="validation.firstError('dialog.create.model.invoice.email')">
                    </v-text-field>
                  </v-col>
                </template>
                
              </v-row>
               <!-- SECTION - 綁定收款帳戶資訊 -->
              <span class="text-h3">收款帳戶資訊</span>
              <v-row class="mt-5">
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="dialog.create.model.beneficiary.bankId"
                    :items="filteredBanks"
                    :search-input.sync="form.bank.keyword"
                    item-text="label"
                    item-value="bank_id"
                    prepend-icon="mdi-bank"
                    dense
                    outlined
                    :label="$t('views.merchants.dialog.beneficiary.bankNo')"
                  >
                    <template v-slot:selection="data">
                      {{  data.item.label}}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.beneficiary.accountId')" prepend-icon="mdi-pound"
                    v-model="dialog.create.model.beneficiary.accountId">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.beneficiary.accountName')" prepend-icon="mdi-account-outline"
                    v-model="dialog.create.model.beneficiary.accountName">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.beneficiary.accountNo')" prepend-icon="mdi-pound"
                    v-model="dialog.create.model.beneficiary.accountNo">
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- SECTION - 出帳週期 -->
              <span class="text-h3">{{ $t('views.merchants.dialog.accountingPeriod') }} </span>
              <v-row class="mt-5">
                <v-col cols="12" md="6">
                  <v-select outlined dense :placeholder="$t('views.merchants.dialog.accountingPeriod')" 
                    prepend-icon="mdi-pound"
                    :items="accountingPeriodOpts" hide-details
                    v-model="dialog.create.model.accountingPeriod">
                  </v-select>
                </v-col>
               
              </v-row>
              <!-- SECTION - 營業日切換時間 -->
              <br/>
              <span class="text-h3">{{ $t('views.merchants.dialog.crossDayCutOffTime') }} </span>
              <v-row class="mt-5" align="center">
                <v-col
                  class="d-flex"
                  cols="5"
                  md="2"
                >
                  <v-select outlined dense
                    :items="hours" hide-details
                    v-model="dialog.create.model.selectedCrossDayCutOffHour">
                  </v-select>
                </v-col>
                <v-col
                  class="d-flex"
                  cols="1"
                >
                  <b>：</b>
                </v-col>
                <v-col
                  class="d-flex"
                  cols="5"
                  md="2"
                >
                  <v-select outlined dense
                    :items="minutes" hide-details
                    v-model="dialog.create.model.selectedCrossDayCutOffMinute">
                  </v-select>
                </v-col>
                             
              </v-row>

              <!-- SECTION - 手續費率 -->
              <br v-if="isSuperAdmin" />
              <span v-if="isSuperAdmin" class="text-h3">{{ $t('views.merchants.dialog.commissionFee') }} </span>
              <v-row v-if="isSuperAdmin"  class="mt-5" align="center">
                <v-col cols="12" md="6">
                  <v-autocomplete 
                      v-model="dialog.create.model.formulaId" 
                      outlined dense
                      prepend-icon="mdi-hand-coin"
                      :items="formulas" 
                      item-text="name"
                      item-value="id"
                      hide-details>
                    </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn @click="dialog.create.show = false; this.form.bank.keyword= ''; validation.reset();"
            :disabled="dialog.create.isLoading">
            {{ $t('views.merchants.dialog.buttonCancel') }}
          </v-btn>
          <v-btn color="amber" @click="createMerchant"
            :disabled="dialog.create.isLoading">
            {{ $t('views.merchants.dialog.buttonCreate') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog - Edit a merchant -->
    <v-dialog v-model="dialog.update.show" width="unset">
      <v-card>
        <v-card-title>
          <strong>{{ $t('views.merchants.dialog.titleUpdate') }} |&nbsp;</strong>{{ dialog.update.merchant.name }}
        </v-card-title>

        <v-card-text>
          
          <v-form ref="mch-update-form" :disabled="dialog.update.isLoading">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.merchantName')" prepend-icon="mdi-store"
                    v-model="dialog.update.model.name"
                    :error-messages="validation.firstError('dialog.update.model.name')">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.customerMerchantId')" prepend-icon="mdi-pound"
                    v-model="dialog.update.model.customerMerchantId"
                    :error-messages="validation.firstError('dialog.update.model.customerMerchantId')">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.email')" prepend-icon="mdi-email"
                    v-model="dialog.update.model.email"
                    :error-messages="validation.firstError('dialog.update.model.email')">
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.phone')" prepend-icon="mdi-phone"
                    v-model="dialog.update.model.phone"
                    :error-messages="validation.firstError('dialog.update.model.phone')">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.address')" prepend-icon="mdi-map-marker"
                    v-model="dialog.update.model.address"
                    :error-messages="validation.firstError('dialog.update.model.address')">
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- SECTION - 綁定發票資訊 -->
              <span class="text-h3">發票資訊</span>
              <v-row>
                <v-col cols="12">
                  <v-radio-group row v-model="dialog.update.model.invoice.type">
                    <template v-slot:label>
                      <div>{{ $t('views.merchants.invoice.type') }}<br></div>
                    </template>
                    <v-radio :label="$t('views.merchants.invoice.NONE')" value="n/a"></v-radio>
                    <v-radio :label="$t('views.merchants.invoice.B2C')" value="b2c"></v-radio>
                    <v-radio :label="$t('views.merchants.invoice.B2B')" value="b2b"></v-radio>                    
                  </v-radio-group>
                </v-col>
                <template  v-if="dialog.update.model.invoice.type==='b2b'" >
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense :label="$t('views.merchants.invoice.title')" prepend-icon="mdi-store"
                      v-model="dialog.update.model.invoice.title"
                      :error-messages="validation.firstError('dialog.update.model.invoice.title')">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense :label="$t('views.merchants.invoice.taxId')" prepend-icon="mdi-pound"
                      v-model="dialog.update.model.invoice.taxId"
                      :error-messages="validation.firstError('dialog.update.model.invoice.taxId')">
                    </v-text-field>
                  </v-col>
                </template>
                <template v-if="dialog.update.model.invoice.type!=='n/a'">
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense :label="$t('views.merchants.dialog.email')" prepend-icon="mdi-email"
                      v-model="dialog.update.model.invoice.email"
                      :error-messages="validation.firstError('dialog.update.model.invoice.email')">
                    </v-text-field>
                  </v-col>
                </template>
              </v-row>
              <!-- SECTION - 綁定收款帳戶資訊 -->
              <span class="text-h3">收款帳戶資訊</span>
              <v-row class="mt-5">
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="dialog.update.model.beneficiary.bankId"
                    :items="filteredBanks"
                    :search-input.sync="form.bank.keyword"
                    item-text="label"
                    item-value="bank_id"
                    prepend-icon="mdi-bank"
                    dense
                    outlined
                    :label="$t('views.merchants.dialog.beneficiary.bankNo')"
                  >
                    <template v-slot:selection="data">
                      {{  data.item.label}}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.beneficiary.accountId')" prepend-icon="mdi-pound"
                    v-model="dialog.update.model.beneficiary.accountId">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.beneficiary.accountName')" prepend-icon="mdi-account-outline"
                    v-model="dialog.update.model.beneficiary.accountName">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined dense :label="$t('views.merchants.dialog.beneficiary.accountNo')" prepend-icon="mdi-pound"
                    v-model="dialog.update.model.beneficiary.accountNo">
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- SECTION - 出帳週期 -->
              <span class="text-h3">{{ $t('views.merchants.dialog.accountingPeriod') }} </span>
              <v-row class="mt-5">
                <v-col cols="12" md="6">
                  <v-select outlined dense :placeholder="$t('views.merchants.dialog.accountingPeriod')" 
                    prepend-icon="mdi-pound"
                    :items="accountingPeriodOpts" hide-details
                    v-model="dialog.update.model.accountingPeriod">
                  </v-select>
                </v-col>
               
              </v-row>
               <!-- SECTION - 營業日切換時間 -->
               <br/>
              <span class="text-h3">{{ $t('views.merchants.dialog.crossDayCutOffTime') }} </span>
              <v-row class="mt-5" align="center">
                <v-col
                  class="d-flex"
                  cols="5"
                  md="2"
                >
                  <v-select outlined dense
                    :items="hours" hide-details
                    v-model="dialog.update.model.selectedCrossDayCutOffHour">
                  </v-select>
                </v-col>
                <v-col
                  class="d-flex"
                  cols="1"
                >
                  <b>：</b>
                </v-col>
                <v-col
                  class="d-flex"
                 cols="5"
                  md="2"
                >
                  <v-select outlined dense
                    :items="minutes" hide-details
                    v-model="dialog.update.model.selectedCrossDayCutOffMinute">
                  </v-select>
                </v-col>
                             
              </v-row>
              <!-- SECTION - 手續費率 -->
              <br v-if="isSuperAdmin" />
              <span v-if="isSuperAdmin" class="text-h3">{{ $t('views.merchants.dialog.commissionFee') }} </span>
              <v-row v-if="isSuperAdmin" class="mt-5" align="center">
                <v-col cols="12" md="6">
                  <v-autocomplete 
                      v-model="dialog.update.model.formulaId" 
                      outlined dense
                      prepend-icon="mdi-hand-coin"
                      :items="formulas" 
                      item-text="name"
                      item-value="id"
                      hide-details>
                    </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn @click="dialog.update.show = false; this.form.bank.keyword= '';" :disabled="dialog.update.isLoading">
            {{ $t('views.merchants.dialog.buttonCancel') }}
          </v-btn>
          <v-btn color="error" @click="deleteMerchant" :disabled="dialog.update.isLoading">
            {{ $t('views.merchants.dialog.buttonDelete') }}
          </v-btn>
          <v-btn color="primary" @click="updateMerchant" :disabled="dialog.update.isLoading">
            {{ $t('views.merchants.dialog.buttonUpdate') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable';
import ScopeSelector from '@/components/ScopeSelector';

import UploadPreviewDialog from '@/components/UploadPreviewDialog';
import PaymentConfigDialog from '@/views/dashboard/subviews/PaymentConfigDialog';
import CounterSettingsView from '@/views/dashboard/subviews/CounterSettingsView';
import MerchantSettingsView from '@/views/dashboard/subviews/MerchantSettingsView';

import SimpleVueValidation from 'simple-vue-validator';
import axios from 'axios';

const Validator = SimpleVueValidation.Validator;

export default {
  components: {
    SSRTable,
    ScopeSelector,
    UploadPreviewDialog,
    PaymentConfigDialog,
    CounterSettingsView,
    MerchantSettingsView
  },

  mounted() {
    this.fetchBanks();
    this.fetchCommissionFee();
  },

  methods: {
    search() {
      console.log('[DEBUG] search', this.form.search);
      if (this.form.search.keyword)
        this.form.search.searchQueryString = `&search=${this.form.search.searchBy}:${encodeURIComponent(this.form.search.keyword)}`;
    },
    onOrganizationSelected(org) {
      this.scope.organization = org;
    },

    async fetchMerchant(merchantId) {
      try {
        let response = await axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${merchantId}`);
        return response.data;
      } catch (err) {
        console.error(`Failed to fetch merchant info if ${merchantId}`, err);
        this.$notify({
          title: 'Cannot fetch merchant information!',
          type: 'error'
        });
        throw err;
      }
    },

    showDialogOfPaymentConfigs(merchant) {
      this.dialog.paymentConfigs.merchant = merchant;
      this.dialog.paymentConfigs.show = true;
    },
    showDialogOfCounterSettings(merchant) {
      this.dialog.counters.merchant = merchant;
      this.dialog.counters.show = true;
    },
    showDialogOfMerchantSettings(merchant) {
      this.dialog.settings.merchant = merchant;
      this.dialog.settings.show = true;
    },
    showDialogOfMigration(merchant) {
      this.dialog.migrate.merchant = merchant;
      if (!this.organizations.length) {
        axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs`)
        .then(response => {
          this.organizations = response.data;
          this.dialog.migrate.show = true;
        })
        .catch(err => {
          console.error(`[ScopeSelector] fetchOrganization error`, err);
        });
      }
      else {
        this.dialog.migrate.show = true;
      }
    },
    showUpdateMerchantDialog(merchantId) {
      this.validation.reset();
      this.fetchMerchant(merchantId)
      .then(merchant => {
        this.dialog.update.merchant = merchant;
        const cleanedTime = merchant['cross_day_cut_off_time'] ? merchant['cross_day_cut_off_time'].replace(
          /\s+/g,
          ""
        ) : "00:00";

        this.dialog.update.model = {
          name: merchant['name'], customerMerchantId: merchant['customer_merchant_id'], email: merchant['email'],
          phone: merchant['phone'], address: merchant['address'],
          invoice: {
            type: merchant['invoice']?.type ? merchant['invoice'].type: '',
            type: merchant['invoice']?.type ? ((merchant['invoice'].type === 'b2b' && !merchant['invoice']?.taxId) ? '': merchant['invoice'].type): '',
            taxId: merchant['invoice']?.taxId ? merchant['invoice'].taxId : '',
            email: merchant['invoice']?.email ? merchant['invoice'].email : '',
            title: merchant['invoice']?.title ? merchant['invoice'].title: '',
          }, 
          beneficiary: {
            bankName: merchant['beneficiary']? merchant['beneficiary']['bankName'] : '',
            bankId: merchant['beneficiary']? merchant['beneficiary']['bankId'] : '',
            accountName: merchant['beneficiary']? merchant['beneficiary']['accountName'] : '',
            accountNo: merchant['beneficiary']? merchant['beneficiary']['accountNo'] : '',
            accountId: merchant['beneficiary']? merchant['beneficiary']['accountId'] : '',
          },
          accountingPeriod: merchant['accounting_period'],
          formulaId: merchant['formula_id'] ||  this.defaultFormulaId,
          selectedCrossDayCutOffHour: cleanedTime.substring(0, 2),
          selectedCrossDayCutOffMinute: cleanedTime.substring(3, 5)

        };
        this.dialog.update.show = true;
      });
      
    },
    showBatchCreationDialog() {
      if (this.user['level'] === 'superadmin' && !this.scope.organization) {
        alert(`You must pick an organization first!`);
        return;
      }
      this.dialog.batchCreate.show = true;
    },

    onCreateFormOrgSelected(org) {
      if (org)
        this.dialog.create.model.orgId = org['id'];
    },

    createMerchant() {
      if (this.user['level'] === 'superadmin' && !this.dialog.create.model.orgId) {
        this.$notify({
          title: 'To add a merchant, you must specify an organization.',
          type: 'error'
        })
        return;
      }
      
      let validators = [
        'dialog.create.model.merchantId',
        'dialog.create.model.name',
        'dialog.create.model.customerMerchantId',
        'dialog.create.model.email',
        'dialog.create.model.phone',
        'dialog.create.model.address',
        
      ];
      if (this.dialog.create.model.invoice.type === 'b2b') {
        validators.push('dialog.create.model.invoice.title');
        validators.push('dialog.create.model.invoice.taxId');
        validators.push('dialog.create.model.invoice.email');
      } else if (this.dialog.create.model.invoice.type === 'b2c'){
        validators.push('dialog.create.model.invoice.email');
      }
      this.$validate(validators)
      .then(valid => {
        if (!valid) return;

        this.dialog.create.isLoading = true;
        let data = Object.assign({}, this.dialog.create.model);
        if(data?.customerMerchantId){
          data.customerMerchantId = data.customerMerchantId.trim()
        }
        if(data?.merchantId){
          data.merchantId = data.merchantId.replace(/\s+/g, '')
        }
        data.crossDayCutOffTime = data.selectedCrossDayCutOffHour + ':' + data.selectedCrossDayCutOffMinute;

        axios.post(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants`,
          data
        )
        .then(response => {
          this.$refs['mch-list'].loadData();
          this.$notify({
            text: `Merchant created: ${this.dialog.create.model.name}.`,
            type: 'success'
          });
        })
        .catch(err => {
          console.error(`Failed to create a new merchant`, err);
          this.$notify({
            text: `Merchant creation failed (${this.dialog.create.model.name}): ${err.message}`,
            type: 'error'
          });
        })
        .finally(() => {          
          this.dialog.create.show = false;
          this.dialog.create.isLoading = false;
          // Reset some form fields.
          this.resetCreateModel();
          // this.dialog.create.model.merchantId = '';
          // this.dialog.create.model.name = '';
          // this.dialog.create.model.customerMerchantId = '';
          // this.dialog.create.model.phone = '';
          // this.dialog.create.model.email = '';
          // this.dialog.create.model.address = '';

          // this.dialog.create.model.invoice.type = '';
          // this.dialog.create.model.invoice.email = '';
          // this.dialog.create.model.invoice.title = '';
          // this.dialog.create.model.invoice.taxId = '';

          // this.dialog.create.model.beneficiary.bankId = '';
          // this.dialog.create.model.beneficiary.bankName = '';
          // this.dialog.create.model.beneficiary.accountId = '';
          // this.dialog.create.model.beneficiary.accountNo = '';
          // this.dialog.create.model.beneficiary.accountName = '';

          // this.dialog.create.model.accountingPeriod = '1w';
          // this.dialog.create.model.selectedCrossDayCutOffHour = '00';
          // this.dialog.create.model.selectedCrossDayCutOffMinute = '00';

          this.form.bank.keyword= '';
        });
      });
    },
    resetCreateModel () {
      // Reset some form fields.
      this.dialog.create.model.merchantId = '';
      this.dialog.create.model.name = '';
      this.dialog.create.model.customerMerchantId = '';
      this.dialog.create.model.phone = '';
      this.dialog.create.model.email = '';
      this.dialog.create.model.address = '';

      this.dialog.create.model.invoice.type = '';
      this.dialog.create.model.invoice.email = '';
      this.dialog.create.model.invoice.title = '';
      this.dialog.create.model.invoice.taxId = '';

      this.dialog.create.model.beneficiary.bankId = '';
      this.dialog.create.model.beneficiary.bankName = '';
      this.dialog.create.model.beneficiary.accountId = '';
      this.dialog.create.model.beneficiary.accountNo = '';
      this.dialog.create.model.beneficiary.accountName = '';

      this.dialog.create.model.accountingPeriod = '1w';
      this.dialog.create.model.formulaId = this.defaultFormulaId;
      this.dialog.create.model.selectedCrossDayCutOffHour = '00';
      this.dialog.create.model.selectedCrossDayCutOffMinute = '00';
    },
    updateMerchant() {
      let validators = [
        'dialog.update.model.name',
        'dialog.update.model.customerMerchantId',
        'dialog.update.model.email',
        'dialog.update.model.phone',
        'dialog.update.model.address',
      ];
      if (this.dialog.update.model.invoice.type === 'b2b') {
        validators.push('dialog.update.model.invoice.title');
        validators.push('dialog.update.model.invoice.taxId');
        validators.push('dialog.update.model.invoice.email');
      } else if (this.dialog.update.model.invoice.type === 'b2c'){
        validators.push('dialog.update.model.invoice.email');
      }
      this.$validate(validators)
      .then(valid => {
        if (!valid) return;

        let merchantId = this.dialog.update.merchant['merchant_id'];
        this.dialog.update.isLoading = true;
        let data = Object.assign({},this.dialog.update.model);      
        if(data?.customerMerchantId){
          data.customerMerchantId = data.customerMerchantId.trim()
        }

        data.crossDayCutOffTime = data.selectedCrossDayCutOffHour + ':' + data.selectedCrossDayCutOffMinute;
        
        axios.put(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${merchantId}`,
          data
        )
        .then(response => {
          this.$refs['mch-list'].loadData();
          this.$notify({
            text: `Merchant updated: ${this.dialog.update.model.name}.`,
            type: 'success'
          });
        })
        .catch(err => {
          console.error(`Failed to update merchant`, this.dialog.update.merchant);
          this.$notify({
            text: `Merchant update failed (${this.dialog.update.model.name}): ${err.message}`,
            type: 'error'
          });
        })
        .finally(() => {
          this.dialog.update.show = false;
          this.dialog.update.isLoading = false;
          // Reset some form fields.
          this.dialog.update.model.name = '';
          this.dialog.update.model.customerMerchantId = '';
          this.dialog.update.model.phone = '';
          this.dialog.update.model.email = '';
          this.dialog.update.model.address = '';
          this.dialog.update.model.invoice.type = '';
          this.dialog.update.model.invoice.email = '';
          this.dialog.update.model.invoice.title = '';
          this.dialog.update.model.invoice.taxId = '';
          this.dialog.update.model.beneficiary.bankId = '';
          this.dialog.update.model.beneficiary.bankName = '';
          this.dialog.update.model.beneficiary.accountId = '';
          this.dialog.update.model.beneficiary.accountNo = '';
          this.dialog.update.model.beneficiary.accountName = '';
          this.dialog.update.model.accountingPeriod = '1w';
          this.dialog.update.model.selectedCrossDayCutOffHour = '00';
          this.dialog.update.model.selectedCrossDayCutOffMinute = '00';
          this.form.bank.keyword= '';
        });
      });
    },
    deleteMerchant() {
      if (!confirm(`Sure to delete merchant ${this.dialog.update.merchant['name']}? This cannot be undone!`))
        return;
      
      let merchantId = this.dialog.update.merchant['merchant_id'];
      this.dialog.update.isLoading = true;
      axios.delete(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${merchantId}`,
        {
          data: {
            "orgId": this.dialog.update.merchant['organization_id']?
              this.dialog.update.merchant['organization_id']
              : undefined
          }
        }
      )
      .then(response => {
        this.$refs['mch-list'].loadData();
        this.$notify({
          text: `Merchant deleted: ${this.dialog.update.model.name}.`,
          type: 'warn'
        });
      })
      .catch(err => {
        console.error(`Failed to delete a merchant`, this.dialog.update.merchant);
        this.$notify({
          text: `Merchant deletion failed (${this.dialog.update.model.name}): ${err.message}`,
          type: 'error'
        });
      })
      .finally(() => {
        this.dialog.update.show = false;
        this.dialog.update.isLoading = false;
        // Reset some form fields.
        this.dialog.update.model.name = '';
        this.dialog.update.model.customerMerchantId = '';
        this.dialog.update.model.phone = '';
        this.dialog.update.model.email = '';
        this.dialog.update.model.address = '';
      });
    },
    batchCreateMerchant(data) {//'data' should be an array.
      if (!confirm(`About to create ${data.length} new merchants. Are you sure?`))
        return;
      data = data.map(row => {//Re-format req body keying.
        let formulaId = row['formula_id'] && Number(row['formula_id']) ? Number(row['formula_id']) : this.defaultFormulaId;
        return {
          merchantId: row['merchant_id'],
          name: row['name'], customerMerchantId: row['customerMerchantId'],  email: row['email'], phone: row['phone'], address: row['address'],
          crossDayCutOffTime: row['cross_day_cut_off_time'],
          formulaId,
          applyDefaultPaymentConfigs: row['apply_def_pay_services'] == '1'? true: false
        };
      });

      axios.post(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/batch`,
        {
          "requests": data,
          "orgId": this.scope.organization? this.scope.organization['id']: undefined
        }
      )
      .then(response => {
        console.log(`[Merchants] batch creation succeeded`, response.data);
        this.$notify({
          text: `Successfully submitted batch.`,
          type: 'success'
        });
        this.$refs['mch-list'].loadData();
      })
      .catch(err => {
        console.error(`[Merchants] batch creation failed`, err);
        let errorMessage = `Failed to do batch creation.`;
        if (err.response && err.response.data && err.response.data.message) {
          errorMessage +=  ` ${err.response.data.message}` ;
        }
        this.$notify({
          text: errorMessage,
          type: 'error'
        });
      });
    },

    fetchBanks(){
      axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/banks`)
      .then(response => {
        this.banks = response.data;
        console.log(`[BANK ACCOUNT] Obtained list of banks`, this.banks);
      })
      .catch(err => {
        console.error(`[BANK ACCOUNT] Cannot fetch bank list`, err);
        this.$notify.error({
          title: '查詢錯誤 - 銀行列表',
          message: err.data.message
        });
      });
    },
  
    async fetchCommissionFee() {
      try {
        let response = await axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/formulas`);
        this.formulas = response.data.formulas;
      } catch (err) {
        console.error(`Failed to fetch commission fee formulas`, err);
        this.$notify({
          title: 'Cannot fetch commission fee formulas!',
          type: 'error'
        });
        throw err;
      }
    },
  },

  data() {
    return {
      scope: {
        organization: null
      },

      form: {
        search: {
          searchBy: 'name',
          keyword: '',
          searchQueryString: ''
        },
        bank: {
          keyword: '',
        }
      },

      dialog: {
        paymentConfigs: {
          show: false,
          merchant: { }
        },
        counters: {
          show: false,
          merchant: { }
        },
        settings: {
          show: false,
          merchant: { }
        },

        create: {
          show: false,
          isLoading: false,
          model: {
            orgId: null,
            merchantId: '',
            name: '', customerMerchantId: '', email: '', phone: '', address: '',
            applyDefaultPaymentConfigs: false,
            invoice: { type: '', email: '', title: '', taxId: ''},
            beneficiary: {bankName: '', bankId: '', accountName: '', accountNo: '', accountId: ''},
            accountingPeriod: '1w',
            formulaId: '',
            selectedCrossDayCutOffHour: "00",
            selectedCrossDayCutOffMinute: "00",
          }
        },
        update: {
          show: false,
          isLoading: false,
          merchant: { },
          model: {
            name: '', customerMerchantId: '', email: '', phone: '', address: '',
            invoice: { type: '', email: '', title: '', taxId: '' },
            beneficiary: {bankName: '', bankId: '', accountName: '', accountNo: '', accountId: ''},
            accountingPeriod: '',
            formulaId: '',
            selectedCrossDayCutOffHour: "00",
            selectedCrossDayCutOffMinute: "00",
          }
        },

        batchCreate: {
          show: false
        },
        
        
      },

      banks: [],
      formulas: []
    };
  },
  watch: {
    "dialog.create.model.beneficiary.bankId": {
      immediate: false,
      handler(val) {
        const bank = this.banks.find(bank => bank.bank_id === val);
        this.dialog.create.model.beneficiary.bankName = bank.bank_name;
      }
    },
    "dialog.update.model.beneficiary.bankId": {
      immediate: false,
      handler(val) {
        const bank = this.banks.find(bank => bank.bank_id === val);
        this.dialog.update.model.beneficiary.bankName = bank ? bank.bank_name : undefined;
      }
      }
    },

  computed: {
    loadUrl() {
      return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}&&include-beneficiaries=false`
        + (this.scope.organization? `&orgId=${this.scope.organization['id']}`: '')
        + this.form.search.searchQueryString;
    },

    isSuperAdmin() {
      return ['superadmin'].indexOf(this.user['level']) >= 0;
    },
    batchCreateUrl() {
      if (['superadmin', 'admin'].indexOf(this.user['level']) >= 0)
        return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/batch`;
      return undefined;
    },
    batchCreateSampleUrl() {
      return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/batch/sample`;
    },
    
    schema() {
      let ret = [
        // { value: 'merchant_id', text: 'Merchant ID', attrs: { width: 144 } },
        { value: 'name', text: this.$t('views.merchants.name'), sortable: false },
       // { value: 'contact', text: this.$t('views.merchants.contact'), sortable: false },
        // { value: 'invoice', text: this.$t('views.merchants.invoiceInfo'), sortable: false },
        // { value: 'beneficiary', text: this.$t('views.merchants.beneficiary'), sortable: false },
        { value: 'created_at', text: this.$t('views.merchants.createdAt') }
      ];
      if (this.user['level'] === 'superadmin')
        ret.push({ value: 'organization_name', text: this.$t('views.merchants.organization') });
      ret.push({ value: 'actions', text: this.$t('views.merchants.actions'), sortable: false });
      return ret;
    },

    user() {
      return this.$store.getters.user;
    },
    userLevelAboveAdmin() {
      return [ 'superadmin', 'admin' ].indexOf(this.$store.getters.user['level']) >= 0;
    },

    filteredBanks() {
      let banks = this.banks.map(bank => {
        return { bank_id: bank.bank_id, bank_name: bank.bank_name, label: `${bank['bank_id']} - ${bank['bank_name']}` };
      });
      let ret = this.form.bank.keyword? banks.filter(bank => bank.label.indexOf(this.form.bank.keyword) >= 0): banks;
      return ret;
    },

    accountingPeriodOpts() { 
      return [
        {
          text: this.$t('views.remittances.weekly'),
          value: '1w'
        },
        {
          text: this.$t('views.remittances.monthly'),
          value: '1m'
        }
      ]
    },

    hours() {
      return Array.from({ length: 24 }, (_, i) =>
          i.toString().padStart(2, "0")
      );
    },
    minutes() {
      return Array.from({ length: 60 }, (_, i) =>
          i.toString().padStart(2, "0")
        );
    },

    defaultFormulaId () {
      return this.formulas.length ? this.formulas[0].id : 1;
    }
  },

  validators: {
    'dialog.create.model.merchantId': v => {
      return Validator.value(v).required().minLength(8);
    },
    'dialog.create.model.name': v => {
      return Validator.value(v).required().minLength(2);
    },
    'dialog.create.model.customerMerchantId': v => {
      return Validator.value(v).maxLength(16).regex(/^[a-zA-Z0-9_]+$/, 'The field must contain only alphabets, digits and _.');
    },
    'dialog.create.model.email': v => {
      return Validator.value(v).required().email();
    },
    'dialog.create.model.phone': v => {
      return Validator.value(v).regex(new RegExp('^(09\\d{8}|\\+8869\\d{8}|0[2-8]\\d{7,8}|\\+886[2-8]\\d{7,8})$', 'gm'));
    },
    'dialog.create.model.address': v => {
      return Validator.value(v).required();
    },
    'dialog.update.model.name': v => {
      return Validator.value(v).required().minLength(2);
    },
    'dialog.update.model.customerMerchantId': v => {
      return Validator.value(v).maxLength(16).regex(/^[a-zA-Z0-9_]+$/, 'The field must contain only alphabets, digits and _.');
    },
    'dialog.update.model.email': v => {
      return Validator.value(v).required().email();
    },
    'dialog.update.model.phone': v => {
      return Validator.value(v).regex(new RegExp('^(09\\d{8}|\\+8869\\d{8}|0[2-8]\\d{7,8}|\\+886[2-8]\\d{7,8})$', 'gm'));
    },
    'dialog.update.model.address': v => {
      return Validator.value(v).required();
    },
    'dialog.create.model.invoice.type': v => {
      return Validator.value(v).required();
    },
    'dialog.create.model.invoice.email': v => {
      return Validator.value(v).required().email();
    },
    'dialog.create.model.invoice.title': v => {
      return Validator.value(v).required().regex(new RegExp('^[\u4e00-\u9fa5]+$', 'gm'));
    },
    'dialog.create.model.invoice.taxId': v => {
      return Validator.value(v).required().regex(new RegExp('^[0-9]{8}$', 'gm'));
    },
    'dialog.update.model.invoice.type': v => {
      return Validator.value(v).required();
    },
    'dialog.update.model.invoice.email': v => {
      return Validator.value(v).required().email();
    },
    'dialog.update.model.invoice.title': v => {
      return Validator.value(v).required().regex(new RegExp('^[\u4e00-\u9fa5]+$', 'gm'));
    },
    'dialog.update.model.invoice.taxId': v => {
      return Validator.value(v).required().regex(new RegExp('^[0-9]{8}$', 'gm'));
    },
  }
}
</script>
